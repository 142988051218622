import { IconButton, Switch, styled } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useState } from 'react';
import { AiOutlineFileSync } from "react-icons/ai";
import { FaLink } from "react-icons/fa6";
import { useModifiedMaterialReactTable } from '../misc/modified_react_table';
import { useModifiedMaterialReactTableNested } from '../misc/modified_react_table_nested';
import { useAllEmissionFactorSummariesLookup } from '../processes/all_emission_factor_summaries_lookup';
import { useEmissionSourcesSynchronization } from '../processes/emission_sources_synchronization';

const BiomassSwitch = styled(Switch)(({ theme }) => ({
  width: 65,
  height: 37,
  paddingBlock: 5,
  paddingInline: 8,
  borderRadius: 100,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 1,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 0 25 25" fill="none" stroke="${encodeURIComponent('#fff')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-rabbit"><path d="M13 16a3 3 0 0 1 2.24 5"/><path d="M18 12h.01"/><path d="M18 21h-8a4 4 0 0 1-4-4 7 7 0 0 1 7-7h.2L9.6 6.4a1 1 0 1 1 2.8-2.8L15.8 7h.2c3.3 0 6 2.7 6 6v1a2 2 0 0 1-2 2h-1a3 3 0 0 0-3 3"/><path d="M20 8.54V4a2 2 0 1 0-4 0v3"/><path d="M7.612 12.524a3 3 0 1 0-1.6 4.3"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
        ...theme.applyStyles('dark', {
          backgroundColor: '#8796A5',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#001e3c',
    width: 34,
    height: 34,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0.3 1 24 23" fill="none" stroke="${encodeURIComponent('#fff')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-car-front"><path d="m21 8-2 2-1.5-3.7A2 2 0 0 0 15.646 5H8.4a2 2 0 0 0-1.903 1.257L5 10 3 8"/><path d="M7 14h.01"/><path d="M17 14h.01"/><rect width="18" height="8" x="3" y="10" rx="2"/><path d="M5 18v2"/><path d="M19 18v2"/></svg>')`,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#003892',
    }),
  },
  display: 'flex',
  alignItems: 'center',
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 50 / 2,
    ...theme.applyStyles('dark', {
      backgroundColor: '#8796A5',
    }),
    height: 23,
  },
}));

function EmisisonFactorDetails({
  emissionFactors = [],
  references = [],
}) {
  const [biomass, setBiomass] = useState(false)

  const emissionFactorTable = useModifiedMaterialReactTableNested({
    muiTablePaperProps: {
      sx: {
        flex: '1 1 0%',
      }
    },
    enableTopToolbar: true,
    enableToolbarInternalActions: false,
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: ({ table }) => {
      return <span
        className={[
          'tw-flex-1',
          'tw-flex',
          'tw-items-center',
        ].join(' ')}
      >
        <BiomassSwitch
          className='tw-text-2xl'
          checked={biomass}
          onChange={(event) => {
            setBiomass(event.target.checked)
          }}
        />
      </span>
    },
    state: {
      columnVisibility: !biomass
        ? {
          co2_biomass: false,
          n2o_biomass: false,
          hfcs_biomass: false,
          pfcs_biomass: false,
          sf6_biomass: false,
          nf3_biomass: false,
        }
        : {
          co2: false,
          n2o: false,
          hfcs: false,
          pfcs: false,
          sf6: false,
          nf3: false,
        }
    },
    data: emissionFactors,
    columns: [
      {
        header: '',
        id: 'empty',
        accessorKey: 'empty',
        enableSorting: false,
        maxSize: 30,
      },
      {
        header: 'Id',
        id: 'id',
        accessorKey: 'id',
        maxSize: 30,
      },
      {
        header: 'Year',
        id: 'year',
        accessorKey: 'year',
        maxSize: 30,
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        header: 'GWP',
        id: 'gwp',
        accessorKey: 'gwp',
        maxSize: 30,
      },
      {
        header: 'Energy',
        id: 'energy',
        accessorKey: 'energy',
        maxSize: 30,
      },
      {
        header: 'CO2',
        id: 'co2',
        accessorKey: 'co2',
        maxSize: 30,
      },
      {
        header: 'CO2',
        id: 'co2_biomass',
        accessorKey: 'co2_biomass',
        maxSize: 30,
      },
      {
        header: 'N2O',
        id: 'n2o',
        accessorKey: 'n2o',
        maxSize: 30,
      },
      {
        header: 'N2O',
        id: 'n2o_biomass',
        accessorKey: 'n2o_biomass',
        maxSize: 30,
      },
      {
        header: 'HFCs',
        id: 'hfcs',
        accessorKey: 'hfcs',
        maxSize: 30,
      },
      {
        header: 'HFCs',
        id: 'hfcs_biomass',
        accessorKey: 'hfcs_biomass',
        maxSize: 30,
      },
      {
        header: 'PFCs',
        id: 'pfcs',
        accessorKey: 'pfcs',
        maxSize: 30,
      },
      {
        header: 'PFCs',
        id: 'pfcs_biomass',
        accessorKey: 'pfcs_biomass',
        maxSize: 30,
      },
      {
        header: 'SF6',
        id: 'sf6',
        accessorKey: 'sf6',
        maxSize: 30,
      },
      {
        header: 'SF6',
        id: 'sf6_biomass',
        accessorKey: 'sf6_biomass',
        maxSize: 30,
      },
      {
        header: 'NF3',
        id: 'nf3',
        accessorKey: 'nf3',
        maxSize: 30,
      },
      {
        header: 'NF3',
        id: 'nf3_biomass',
        accessorKey: 'nf3_biomass',
        maxSize: 30,
      },
    ],
  })
  const referenceTable = useModifiedMaterialReactTableNested({
    data: references,
    columns: [
      {
        header: '',
        id: 'empty',
        accessorKey: 'empty',
        enableSorting: false,
        minSize: 0,
        maxSize: 30,
      },
      {
        header: 'Reference',
        id: 'reference',
        accessorKey: 'label',
        Cell: ({ row, cell }) => {
          return <a
            target='_blank'
            rel='noopener noreferrer'
            href={row.original.link}
            className={[
              'tw-p-1',
              'tw-px-2',
              'tw-border',
              'tw-border-primary',
              'tw-rounded-full',
              'tw-flex',
              'tw-gap-1',
              'tw-items-center',
              'tw-max-w-max',
            ].join(' ')}
          >
            <FaLink className='tw-text-lg' />
            <span className='tw-font-bold'>
              {cell.getValue()}
            </span>
          </a>
        },
      },
      {
        header: '',
        id: 'empty2',
        accessorKey: 'empty2',
        enableSorting: false,
        minSize: 0,
        maxSize: 30,
      },
    ],
  })

  return <div
    className={[
      'tw-flex',
      'tw-gap-3',
    ].join(' ')}
  >
    <MaterialReactTable table={emissionFactorTable} />
    <MaterialReactTable table={referenceTable} />
  </div>
}

export default function EmissionFactors() {
  const {
    data: allEmissionFactorSummaries,
    isLoading: isLoadingAllEmissionFactorSummaries,
    isFetching: isFetchingAllEmissionFactorSummaries,
  } = useAllEmissionFactorSummariesLookup()

  const {
    isPending: emissionSourcesSynchronizationIsInProgress,
    mutate: emissionSourcesSynchronization,
  } = useEmissionSourcesSynchronization()

  const allEmissionFactorSummariesTable = useModifiedMaterialReactTable({
    data: allEmissionFactorSummaries,
    columns: [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'emissionFactorId',
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => {
          return row.emissionName
        },
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'unit',
        header: 'Unit',
        accessorKey: 'emissionUnit',
      },
    ],
    getRowId: (row) => {
      return row.emissionFactorId
    },
    enablePagination: true,
    state: {
      isLoading: isLoadingAllEmissionFactorSummaries,
      showProgressBars: isFetchingAllEmissionFactorSummaries ||
        emissionSourcesSynchronizationIsInProgress,
      isSaving: emissionSourcesSynchronizationIsInProgress,
    },
    renderTopToolbarCustomActions: () => {
      return <span
        className={[
          'tw-flex-1',
          'tw-flex',
          'tw-items-center',
        ].join(' ')}
      >
        <IconButton
          disabled={emissionSourcesSynchronizationIsInProgress}
          onClick={() => {
            emissionSourcesSynchronization()
          }}
        >
          <AiOutlineFileSync className='tw-text-2xl' />
        </IconButton>
      </span>
    },
    // enableRowSelection: false,
    enableRowActions: true,
    // renderRowActions: ({ table, row }) => {
    //   return [
    //     <button
    //       key={row?.original?.id}
    //       title="Confirm changes"
    //       className="btn"
    //     // onClick={
    //     // isRefetchingEmissionCorrections ||
    //     // isMutatingEmissionCorrections
    //     // ? () => { }
    //     // : () => {
    //     // Swal.fire({
    //     //   title: 'Are you sure?',
    //     //   text: "You won't be able to revert this!",
    //     //   icon: 'warning',
    //     //   showCancelButton: true,
    //     //   confirmButtonColor: '#3085d6',
    //     //   cancelButtonColor: '#d33',
    //     // }).then(async (result) => {
    //     //   if (result.isConfirmed) {
    //     //     requestClientsEmissionCorrectionsConfirmation({
    //     //       recordIds: [row?.original?.id]
    //     //     }, {
    //     //       onSettled: () => {
    //     //         table.resetRowSelection()
    //     //       },
    //     //     })
    //     //   }
    //     // })
    //     // }
    //     // }
    //     >
    //       <FaRegCheckCircle fill="green" fontSize={'1.5rem'} />
    //     </button>
    //   ]
    // },
    renderDetailPanel: ({ row }) => {
      return <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.25rem',
        }}
      >
        <EmisisonFactorDetails
          emissionFactors={row.original?.emissionFactors ?? []}
          references={row.original?.references ?? []}
        />
      </div>
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        return table.setExpanded({ [row.id]: !row.getIsExpanded() })
      },
    }),
  })

  return <div
    className={[
      'tw-flex-1',
      'tw-flex',
      'tw-flex-col',
      'tw-items-stretch',
      'tw-p-7',
    ].join(' ')}
  >
    <MaterialReactTable
      table={allEmissionFactorSummariesTable}
    />
  </div>
}

