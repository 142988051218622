import { IconButton } from '@mui/material'
import { MaterialReactTable } from 'material-react-table'
import { useEffect, useRef, useState } from 'react'
import { MdConnectWithoutContact } from "react-icons/md"
import { RiDeleteBin2Line } from 'react-icons/ri'
import { useModifiedMaterialReactTable } from '../misc/modified_react_table'
import { useAllClientSummariesLookup } from '../processes/all_client_summaries_lookup'
import { useAllUserSummariesLookup } from '../processes/all_user_summaries_lookup'
import { useUserClientAssignment, useUserClientAssignmentState } from '../processes/user_client_assignment'


export default function Users() {
  const [
    userSummaryForClientAssignment,
    setUserSummaryForClientAssignment,
  ] = useState()

  const {
    isPending: userClientAssignmentIsInProgress,
  } = useUserClientAssignmentState()

  const {
    data: allUserSummaries,
    isLoading: isLoadingAllUserSummaries,
    isFetching: isFetchingAllUserSummaries,
  } = useAllUserSummariesLookup()
  const allUserSummariesTable = useModifiedMaterialReactTable({
    data: allUserSummaries,
    columns: [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'userUuid',
        Cell: ({ row, cell }) => {
          return <span
            style={{
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'email',
        header: 'Email Address',
        accessorKey: 'userEmail',
        Cell: ({ row, cell }) => {
          return <span
            style={{
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => {
          return row.userName
        },
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'role',
        header: 'Role',
        accessorKey: 'userRole',
      },
    ],
    getRowId: (row) => row.userUuid,
    enablePagination: true,
    state: {
      isLoading: isLoadingAllUserSummaries,
      showProgressBars: isFetchingAllUserSummaries ||
        userClientAssignmentIsInProgress,
      isSaving: userClientAssignmentIsInProgress,
    },
    // enableRowSelection: false,
    enableRowActions: true,
    renderRowActions: ({ table, row }) => {
      return [
        <IconButton
          key={row?.id}
          title="Client Assignment"
          className=""
          onClick={() => {
            setUserSummaryForClientAssignment(row.original)
          }}
        >
          <MdConnectWithoutContact fontSize={'2rem'} />
        </IconButton>
      ]
    },
  })

  return <div
    className={[
      'tw-flex-1',
      'tw-flex',
      'tw-flex-col',
      'tw-items-stretch',
      'tw-p-7',
    ].join(' ')}
  >
    <div className=''>
      <MaterialReactTable
        table={allUserSummariesTable}
      />
    </div>
    <UserClientAssignmentDialog
      userSummary={userSummaryForClientAssignment}
      onClose={() => setUserSummaryForClientAssignment()}
    />
  </div>
}

function UserClientAssignmentDialog({
  userSummary,
  onClose,
}) {
  const {
    data: clientSummaries,
  } = useAllClientSummariesLookup()
  const [client, setClient] = useState(undefined)
  useEffect(() => {
    setClient(
      clientSummaries?.find(clientSummary => {
        return clientSummary.clientId === userSummary?.userClient
      }) ?? undefined
    )
  }, [userSummary, clientSummaries])
  const clientId = client?.clientId
  const clientAssignmentDialog = useRef()
  const {
    isSuccess: userClientAssignmentHasSucceeded,
    mutate: userClientAssignment,
  } = useUserClientAssignment()
  useEffect(() => {
    if (userClientAssignmentHasSucceeded) {
      clientAssignmentDialog.current.close()
    }
  }, [userClientAssignmentHasSucceeded])
  useEffect(() => {
    if (userSummary) {
      clientAssignmentDialog.current.showModal()
    } else {
      clientAssignmentDialog.current.close()
    }
  }, [userSummary])

  return <dialog
    ref={clientAssignmentDialog}
    className='tw-modal tw-modal-bottom sm:tw-modal-middle'
    onKeyDown={(event) => {
      if (event.key === 'Escape') {
        event.stopPropagation()
      }
    }}
    onClose={onClose}
  >
    <div className='tw-modal-box tw-border tw-border-[revert] tw-p-0 tw-flex tw-flex-col'>
      <div
        className={[
          'tw-font-semibold',
          'tw-bg-primary',
          'tw-text-primary-content',
          'tw-p-4',
          'sm:tw-p-5',
          'tw-flex',
          'tw-items-center',
        ].join(' ')}
      >
        User Client Assignment
      </div>
      <div id='assigned-emissions' className={[
        'tw-px-4',
        'sm:tw-px-5',
        'tw-pb-4',
        'sm:tw-pb-5',
        'tw-overflow-y-auto',
      ].join(' ')}>
        <div className='tw-form-control tw-w-full'>
          <label className='tw-label'>
            <span className='tw-label-text'>
              Name
            </span>
          </label>
          <input
            type='text'
            placeholder='/ Type Here'
            className={[
              'tw-input',
              'tw-input-bordered',
              'tw-input-primary',
              'tw-w-full',
            ].join(' ')}
            defaultValue={userSummary?.userName}
            disabled
          />
        </div>
        <div className='tw-form-control tw-w-full'>
          <div className='tw-label'>
            <span className='tw-label-text'>
              Client
            </span>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-3'>
            <div className='tw-flex'>
              <div className='tw-flex-1 tw-flex tw-flex-col'>
                <FilterableSelect
                  className='tw-justify-start tw-border-l-4'
                  placeholder={'/ Client'}
                  idKey={'clientId'}
                  nameKey={'clientName'}
                  values={clientSummaries}
                  value={client}
                  onChange={(newClient) => setClient(
                    newClient
                  )}
                />
              </div>
              <button className={[
                'tw-flex',
                'tw-justify-center',
                'tw-items-center',
                'tw-p-1',
                'tw-border',
                'tw-border-[revert]',
                'tw-border-l-0',
              ].join(' ')}>
                <RiDeleteBin2Line
                  className='tw-text-2xl'
                  onClick={() => {
                    setClient()
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className='tw-pt-4'>
          <button
            type='button'
            className='tw-btn tw-btn-primary tw-w-full'
            onClick={() => {
              userClientAssignment({
                userId: userSummary.userId,
                clientId: clientId,
              })
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <form method='dialog' className='tw-modal-backdrop'>
      <button>close</button>
    </form>
  </dialog>
}

function FilterableSelect({
  className,
  idKey,
  nameKey,
  placeholder,
  values,
  value,
  valueIndex,
  onChange,
}) {
  const [search, setSearch] = useState('')
  const searchedValues = values?.filter(value => {
    const name = value[nameKey]
    return name.toLowerCase().includes(search.toLowerCase())
  })
  const dialog = useRef()
  const [show, setShow] = useState(false)
  useEffect(() => {
    show && dialog.current.showModal()
  }, [show])
  const [position, setPosition] = useState(0)
  useEffect(() => {
    setPosition(0)
  }, [search, show])
  const option = useRef()
  useEffect(() => {
    option.current?.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
    })
  }, [position])

  return <>
    <button
      onClick={() => setShow(true)}
      className={[
        'tw-btn',
        'tw-btn-outline',
        'tw-btn-primary',
        'tw-no-animation',
        !value ? 'tw-text-violet-400' : '',
        ...className?.split(' '),
      ].join(' ')}
    >
      {value?.[nameKey] ?? placeholder}
    </button>
    {show && <dialog
      ref={dialog}
      className='tw-modal tw-modal-bottom sm:tw-modal-middle'
      onKeyDown={(event) => {
        event.stopPropagation()
        if (
          event.key === 'ArrowDown' &&
          position + 1 < searchedValues?.length
        ) {
          setPosition(position + 1)
        } else if (
          event.key === 'ArrowUp' &&
          position - 1 > -1
        ) {
          setPosition(position - 1)
        } else if (
          event.key === 'Enter' &&
          searchedValues?.length
        ) {
          dialog.current.close()
          onChange(searchedValues[position], valueIndex)
        }
      }}
      onClose={(event) => {
        event.stopPropagation()
        setSearch('')
        setShow(false)
      }}
    >
      <div className={[
        'tw-modal-box',
        'tw-border',
        'tw-border-[revert]',
        'tw-p-0',
        'tw-flex',
        'tw-flex-col',
        'tw-h-full',
      ].join(' ')}>
        <div className={[
          'tw-px-4',
          'sm:tw-px-5',
          'tw-py-1',
          'sm:tw-py-2',
          'tw-bg-primary',
        ].join(' ')}>
          <input
            type='text'
            placeholder='/ Type Something'
            className={[
              'tw-input',
              'tw-bg-primary',
              'tw-text-primary-content',
              'tw-font-semibold',
              'placeholder:tw-text-primary-content',
              'placeholder:tw-font-semibold',
              'tw-w-full',
              'tw-p-0',
              'focus:tw-outline-none',
            ].join(' ')}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value)
            }}
          />
        </div>
        <div
          id='dialog-body'
          className={[
            'tw-overflow-y-auto',
          ].join(' ')}
        >
          <div className='tw-flex-1 tw-flex tw-flex-col'>
            {searchedValues?.map((value, index) => {
              return <button
                ref={index === position ? option : null}
                className={[
                  'tw-flex-1',
                  'tw-btn',
                  'tw-btn-primary',
                  'tw-no-animation',
                  'tw-border-t-0',
                  'tw-border-x-0',
                  'tw-border-l-4',
                  'tw-justify-start',
                  'focus:tw-outline-none',
                  'tw-px-3',
                  'sm:tw-px-4',
                  index === position ? '' : 'tw-btn-outline',
                ].join(' ')}
                key={value[idKey]}
                onClick={() => {
                  dialog.current.close()
                  onChange(value, valueIndex)
                }}
              >
                {value[nameKey]}
              </button>
            })}
          </div>
        </div>
      </div>
      <form method='dialog' className='tw-modal-backdrop'>
        <button>close</button>
      </form>
    </dialog >}
  </>
}