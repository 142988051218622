import { useQuery } from "@tanstack/react-query"
import Cookies from "js-cookie"

export function useSessionLookup() {
  return useQuery({
    queryKey: ['session-lookup'],
    queryFn: async ({ signal }) => {
      const prefix = new URL(process.env.REACT_APP_API_URL).hostname
      const key = `${prefix}_signedIn`
      const session = Cookies.get(key) === 'true'
      
      return session
    },
  })
}
