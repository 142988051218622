import React from 'react'

function NotFound() {
  return <div className={[
    'tw-flex',
    'tw-items-center',
    'tw-justify-center',
    'tw-min-h-screen',
    'tw-font-bold',
    'tw-text-3xl',
  ].join(' ')}>
    🤷🏽‍♀️
  </div>
}

export default NotFound
