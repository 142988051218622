/* eslint-disable react/jsx-pascal-case */
import { Box, IconButton, Tab, Tabs } from '@mui/material'
import { MRT_ToggleGlobalFilterButton, MaterialReactTable, } from 'material-react-table'
import { useState } from 'react'
import { FaClipboardCheck } from "react-icons/fa6"
import { GrDocumentDownload } from "react-icons/gr"
import { RiInformationFill, RiPulseFill, RiShieldFill } from 'react-icons/ri'
import { useModifiedMaterialReactTable } from '../misc/modified_react_table'
import { useModifiedMaterialReactTableNested } from '../misc/modified_react_table_nested'
import { useAllEligibilityCheckSummariesLookup } from '../processes/all_eligibility_check_summaries_lookup'
import { useEligibilityCheckFinalization } from '../processes/eligibility_check_finalization'

function TabPanel({ children, value, index, ...other }) {
  return <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && <Box
    // sx={{ p: 3 }}
    >
      {children}
    </Box>}
  </div>
}

function EligibilityCheckDetails({
  eligibilityChecks = [],
  status = '',
}) {
  const useEligbilityCheckTable = (eligibilityChecks) => {
    return useModifiedMaterialReactTableNested({
      muiTablePaperProps: {
        sx: {
          flex: '1 1 0%',
          borderRadius: '0',
          borderStartEndRadius: '7px',
          borderEndStartRadius: '7px',
          borderEndEndRadius: '7px',
          // border: '1px solid #B3B4C6',
        }
      },
      muiTableBodyProps: {
        sx: {
          borderTop: '1px solid #B3B4C6'
        }
      },
      enableTopToolbar: true,
      enableToolbarInternalActions: true,
      renderToolbarInternalActions: ({ table }) => {
        return <>
          <MRT_ToggleGlobalFilterButton table={table} />
        </>
      },
      enableSorting: false,
      enableGlobalFilter: true,
      getRowId: (row) => row.id,
      // renderTopToolbarCustomActions: ({ table }) => {
      //   return <span
      //     className={[
      //       'tw-flex-1',
      //       'tw-flex',
      //       'tw-items-center',
      //     ].join(' ')}
      //   >
      //   </span>
      // },
      data: eligibilityChecks,
      layoutMode: 'grid-no-grow',
      enableTableHead: false,
      enableFilterMatchHighlighting: false,
      enableEditing: true,
      editDisplayMode: 'cell',
      columns: [
        {
          header: '',
          id: 'empty',
          accessorKey: 'empty',
          enableSorting: false,
          enableEditing: false,
          maxSize: 10,
        },
        {
          header: '',
          id: 'name',
          accessorFn: (row) => {
            return row?.label?.['en-US']
          },
          Cell: ({ row, cell }) => {
            return <span
              className='tw-font-bold'
            >
              {cell.getValue()}
            </span>
          },
          enableSorting: false,
          enableEditing: false,
          size: 400,
          grow: 0,
        },
        {
          header: '',
          id: 'value',
          accessorFn: (field) => {
            if (!field.value) {
              return 'No Data'
            } else if (field.type === 'file') {
              return field.value?.fileName
            } else if (field.type === 'select' && !field.multiple) {
              return field?.value?.label['en-US']
            } else if (field.type === 'select' && field.multiple) {
              return field.value?.map(value => {
                return value.label['en-US']
              }).join(', ')
            } else {
              return field?.value
            }
          },
          Cell: ({ row, cell }) => {
            const field = row.original

            if (!field.value) {
              return <span
              // className='tw-font-bold'
              >
                No Data
              </span>
            } else if (field.type === 'file') {
              return <a
                download={field.value?.fileName}
                href={field.value?.base64}
                className={[
                  'tw-flex',
                  'tw-flex-row-reverse',
                  'tw-justify-between',
                  'tw-items-center',
                  'tw-gap-2',
                ].join(' ')}
              >
                <span
                // className='tw-font-bold'
                >
                  {field.value?.fileName}
                </span>
                <GrDocumentDownload className='tw-text-2xl' />
              </a>
            } else if (field.type === 'select' && !field.multiple) {
              return <span
              // className='tw-font-bold'
              >
                {field.value?.label['en-US']}
              </span>
            } else if (field.type === 'select' && field.multiple) {
              return <span
              // className='tw-font-bold'
              >
                {field.value?.map(value => {
                  return value.label['en-US']
                }).join(', ')}
              </span>
            } else {
              return <span
              // className='tw-font-bold'
              >
                {field.value}
              </span>
            }
          },
          enableSorting: false,
          enableEditing: false,
          grow: 1,
        },
        {
          header: 'Score',
          id: 'score',
          accessorFn: (row) => {
            return row?.score ?? 0
          },
          enableSorting: false,
          enableEditing: (row) => {
            return status === 'submitted'
          },
          muiEditTextFieldProps: ({ row }) => ({
            type: 'number',
            onKeyDown: (event) => {
              if (event.key === 'Escape') {
                event.target.value = row.original.score
                event.target.blur()
              }
            },
            onChange: (event) => {
              if (!event.target.value || Number(event.target.value) < 0) {
                event.target.value = 0
              } else if (Number(event.target.value) > 5) {
                event.target.value = 5
              }
            },
            onBlur: (event) => {
              const score = Number(event.target.value)

              row.original.score = score
            },
            sx: {
              '& input': {
                padding: 0,
              },
              '& :before': {
                display: 'none',
              },
              '& :after': {
                display: 'none',
              },
            },
          }),
          grow: 0,
        },
      ],
    })
  }

  const eligibilityCheckSection1Table = useEligbilityCheckTable(
    eligibilityChecks.filter(check => check.step === 1),
  )
  const eligibilityCheckSection2Table = useEligbilityCheckTable(
    eligibilityChecks.filter(check => check.step === 2),
  )
  const eligibilityCheckSection3Table = useEligbilityCheckTable(
    eligibilityChecks.filter(check => check.step === 3),
  )
  const [tabIndex, setTabIndex] = useState(0)

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      // gap: '1.25rem',
    }}
  >
    <Box sx={{
      // borderBottom: 1, 
      // borderColor: 'divider',
    }}>
      <Tabs
        value={tabIndex}
        onChange={(_, newValue) => setTabIndex(newValue)}
        aria-label="eligibility check sections"
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: '0.25rem',
          },
          "& button.Mui-selected": {
            color: "var(--vz-primary)"
          }
        }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: 'var(--vz-primary)',
          },
        }}
      >
        <Tab
          sx={{
            textTransform: 'none',
            borderTop: '1px solid #B3B4C6',
            borderInline: '1px solid #B3B4C6',
            borderStartStartRadius: '15px',
            borderStartEndRadius: '15px',
          }}
          // iconPosition='start'
          icon={<RiInformationFill className='tw-text-2xl' />}
          label="General Information"
        />
        <Tab
          sx={{
            textTransform: 'none',
            borderTop: '1px solid #B3B4C6',
            borderInline: '1px solid #B3B4C6',
            borderStartStartRadius: '15px',
            borderStartEndRadius: '15px',
          }}
          // iconPosition='start'
          icon={<RiPulseFill className='tw-text-2xl' />}
          label="Biophysical"
        />
        <Tab
          sx={{
            textTransform: 'none',
            borderTop: '1px solid #B3B4C6',
            borderInline: '1px solid #B3B4C6',
            borderStartStartRadius: '15px',
            borderStartEndRadius: '15px',
          }}
          // iconPosition='start'
          icon={<RiShieldFill className='tw-text-2xl' />}
          label="Social Safeguard"
        />
      </Tabs>
    </Box>
    <TabPanel index={0} value={tabIndex}>
      <MaterialReactTable table={eligibilityCheckSection1Table} />
    </TabPanel>
    <TabPanel index={1} value={tabIndex}>
      <MaterialReactTable table={eligibilityCheckSection2Table} />
    </TabPanel>
    <TabPanel index={2} value={tabIndex}>
      <MaterialReactTable table={eligibilityCheckSection3Table} />
    </TabPanel>
  </div>
}

export default function EligibilityChecks() {
  const {
    isPending: eligibilityCheckFinalizationIsInProgress,
    mutate: eligibilityCheckFinalization,
  } = useEligibilityCheckFinalization()

  const {
    data: allEligibilityCheckSummaries,
    isLoading: isLoadingAllEligibilityCheckSummaries,
    isFetching: isFetchingAllEligibilityCheckSummaries,
  } = useAllEligibilityCheckSummariesLookup()

  const allEligibilityCheckSummariesTable = useModifiedMaterialReactTable({
    data: allEligibilityCheckSummaries,
    columns: [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'submitter',
        header: 'Submitter',
        accessorFn: (row) => {
          return row.submitterName
        },
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
      },
      {
        id: 'score',
        header: 'Score',
        accessorKey: 'score',
      },
    ],
    getRowId: (row) => {
      return row.emissionFactorId
    },
    enablePagination: true,
    state: {
      isLoading: isLoadingAllEligibilityCheckSummaries,
      showProgressBars: isFetchingAllEligibilityCheckSummaries ||
        eligibilityCheckFinalizationIsInProgress,
      isSaving: eligibilityCheckFinalizationIsInProgress,
    },
    // enableRowSelection: false,
    enableRowActions: true,
    renderRowActions: ({ table, row }) => {
      return [
        <IconButton
          key={row?.id}
          title="Finalization"
          disabled={row.original.status !== 'submitted'}
          onClick={() => {
            if (window.confirm('Are you sure?')) {
              eligibilityCheckFinalization(row.original)
            }
          }}
        >
          <FaClipboardCheck
            fontSize={'1.5rem'}
          />
        </IconButton>
      ]
    },
    renderDetailPanel: ({ row }) => {
      return <EligibilityCheckDetails
        eligibilityChecks={row.original?.document ?? []}
        status={row.original?.status ?? ''}
      />
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        return table.setExpanded({ [row.id]: !row.getIsExpanded() })
      },
    }),
  })

  return <div
    className={[
      'tw-flex-1',
      'tw-flex',
      'tw-flex-col',
      'tw-items-stretch',
      'tw-p-7',
    ].join(' ')}
  >
    <MaterialReactTable
      table={allEligibilityCheckSummariesTable}
    />
  </div>
}