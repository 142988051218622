import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { Private } from './misc/private'
import { Root } from './misc/root'
import Toast from './misc/toast'
import Clients from './pages/clients'
import EligibilityChecks from './pages/eligibility_checks'
import EmissionCategories from './pages/emission_categories'
import EmissionFactors from './pages/emission_factors'
import Home from './pages/home'
import NotFound from './pages/not_found'
import Users from './pages/users'

function reportWebVitals(onPerfEntry) {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    })
  }
}

const {
  REACT_APP_ACCOUNT_URL: accountUrl,
  REACT_APP_API_URL: apiUrl,
} = process.env

if (!accountUrl || !apiUrl) {
  throw new Error('some react settings are missing')
}

const router = createBrowserRouter([
  {
    errorElement: <NotFound />,
    element: <Private accountUrl={accountUrl} />,
    children: [
      {
        element: <Root />,
        children: [
          {
            path: '/',
            element: <Home />,
          },
          {
            path: '/users',
            element: <Users />,
          },
          {
            path: '/clients',
            element: <Clients />,
          },
          {
            path: '/emission-categories',
            element: <EmissionCategories />,
          },
          {
            path: '/emission-factors',
            element: <EmissionFactors />,
          },
          {
            path: '/eligibility-checks',
            element: <EligibilityChecks />,
          },
        ]
      },
    ],
  },
])

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      {[
      ]?.reduceRight((children, Provider) => {
        return <Provider>
          {children}
        </Provider>
      }, <>
        <Toast />
        <RouterProvider router={router} />
      </>)}
    </QueryClientProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()