import deepMerge from "@75lb/deep-merge"
import { useMaterialReactTable } from "material-react-table"

/**
* Description
* @param {import('material-react-table').MRT_TableOptions<TData>} options
* @returns {any}
*/
export function useModifiedMaterialReactTable(options) {
  return useMaterialReactTable(deepMerge({
    enableExpandAll: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableTableFooter: false,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    initialState: { density: 'compact' },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '7px',
        // border: '1px solid #B3B4C6',
        boxShadow: '0 1px 3px rgba(56, 65, 74, .15)',
      },
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: '#F5F6F8',
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 600,
        paddingBlock: '1.5rem',
      }
    },
    muiTableBodyProps: () => ({
      sx: {
        [(() => {
          const n = !options.renderDetailPanel ? 'odd' : '4n+1'
          return `& > tr:nth-of-type(${n}) > td`
        })()]: {
          backgroundColor: '#F5F5F5',
        },
      },
    }),
    muiTableBodyRowProps: ({ row, isDetailPanel }) => {
      return {
        sx: !isDetailPanel ? {
          borderBottomWidth: 0,
        } : {
        },
      }
    },
    muiTableBodyCellProps: ({ row }) => {
      return {
        sx: {
          paddingBlock: '1.5rem',
        }
      }
    },
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableRowSelection: true,
    muiTopToolbarProps: {
      sx: {
        '& > .MuiBox-root': {
          alignItems: 'center',
        },
        '& > div:nth-of-type(3)': {
          alignItems: 'stretch',
          gap: 0,
        },
      }
    },
    // renderDetailPanel: ({ row }) => {
    //   return <div
    //     style={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       gap: '1.25rem',
    //     }}
    //   >
    //     <EmisisonFactorUpdateDetail
    //       emissionCorrection={row.original}
    //     />
    //     <span
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'row',
    //         gap: '0.5rem',
    //         justifyContent: 'end',
    //         paddingInline: '0.25rem',
    //       }}
    //     >
    //       <i>References:</i>
    //       {row?.original?.references?.map((reference, index) => {
    //         return <div key={index}>
    //           <a
    //             key={index}
    //             href={reference.link}
    //             target="_blank"
    //             rel="noreferrer noopener"
    //           >
    //             <span
    //               style={{
    //                 display: "flex",
    //                 gap: "0.25rem",
    //                 alignItems: "center",
    //               }}
    //             >
    //               {
    //                 i18n.language === "id"
    //                   ? reference.label
    //                   : reference.label
    //               }
    //               <ImNewTab />
    //             </span>
    //           </a> {index !== row?.original?.references?.length - 1 && ','}
    //         </div>
    //       })}
    //     </span>
    //   </div>
    // },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        return table.setExpanded({ [row.id]: !row.getIsExpanded() })
      },
    }),
  }, options))
}
