import { useEffect } from "react"
import { Link, Outlet, useLocation } from "react-router-dom"
import SimpleBar from "simplebar-react"
import darkLogo from './dark-logo.webp'
import defaultAvatar from './default_avatar.png'
import shortDarkLogo from './short-dark-logo.png'
import { useSessionTermination } from "../processes/session_termination"



const HeaderSection = () => {
  const setSidebarSize = (size) => {
    document.documentElement.setAttribute("data-sidebar-size", size)
  }
  const onClickHamburgerIcon = () => {
    var windowSize = document.documentElement.clientWidth

    if (windowSize > 767) {
      document.querySelector(".hamburger-icon").classList.toggle("open")
    }

    // To collapse or expand the vertical sidebar
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize <= 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable")
        if (document.documentElement.getAttribute("data-sidebar-size") === "sm") {
          setSidebarSize('')
        } else {
          setSidebarSize('sm')
        }

      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable")
        if (document.documentElement.getAttribute("data-sidebar-size") === "lg") {
          setSidebarSize('sm')
        } else {
          setSidebarSize('lg')
        }
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable")
        setSidebarSize('lg')
      }
    }
  }

  const {
    mutate: sessionTermination,
  } = useSessionTermination()

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            {(
              <button
                type="button"
                className={[
                  'btn',
                  'fs-16',
                  'header-item',
                  'vertical-menu-btn',
                  'topnav-hamburger',
                ].join(' ')}
                id="topnav-hamburger-icon"
                onClick={onClickHamburgerIcon}
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            )}
          </div>

          <div className="d-flex align-items-center">
            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn header-avatar"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    style={{ objectFit: 'scale-down' }}
                    src={defaultAvatar}
                    alt="Avatar"
                  />
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">
                  {'Welcome'}
                </h6>
                <a className="dropdown-item" href="#profile">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">
                    {'Profile'}
                  </span>
                  &nbsp;<sup style={{ color: "#4c638c" }}>
                    {'Soon'}
                  </sup>
                </a>
                <div className="dropdown-divider"></div>
                <button
                  onClick={sessionTermination}
                  className="dropdown-item"
                >
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle" data-key="t-logout">
                    {'Logout'}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

// Main Menu that does not have parents
const ActivableWithIcon = ({
  to,
  id,
  icon,
  label,
}) => {
  return (
    <li className="nav-item">
      <Link
        className='nav-link menu-link'
        to={to}
        id={id}
      >
        <i className={icon}></i>
        <span>{label}</span>
      </Link>
    </li>
  )
}

// Menu that has parents
const Activable = ({
  to,
  id,
  label,
  detail,
}) => {
  return (
    <li className="nav-item" title={detail}>
      <Link
        to={to}
        className='nav-link'
        id={id}
      >
        {label}
      </Link>
    </li>
  )
}

// Expandable and ExpandableWithIcon's direct child
const Showable = ({
  parentId,
  id,
  children,
  custom,
}) => {
  return (
    <div
      className="collapse menu-dropdown"
      style={{ width: custom }}
      data-bs-parent={parentId}
      id={id}
    >
      <ul className="nav nav-sm flex-column">
        {children}
      </ul>
    </div>
  )
}

// Expandable menu that has parents
const Expandable = ({
  childToToggle,
  parentId,
  id,
  label,
  children,
}) => {
  return (
    <li className="nav-item">
      <a
        data-bs-toggle="collapse"
        data-bs-target={`#${childToToggle}`}
        className="nav-link"
        href={`#${childToToggle}`}
        role="button"
        aria-expanded="false"
        aria-controls={childToToggle}
        data-bs-parent={parentId}
        id={id}
      >
        {label}
      </a>
      {children}
    </li>
  )
}

// Expandable menu that does not have parents
const ExpandableWithIcon = ({
  childToToggle,
  id,
  icon,
  label,
  children,
}) => {
  return (
    <li className="nav-item">
      <a
        data-bs-toggle="collapse"
        data-bs-target={`#${childToToggle}`}
        className="nav-link menu-link"
        href={`#${childToToggle}`}
        role="button"
        aria-expanded="false"
        aria-controls={childToToggle}
        id={id}
      >
        <i className={icon}></i>
        <span>
          {label}
        </span>
      </a>
      {children}
    </li>
  )
}

const MenuSection = () => {
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay")
    if (verticalOverlay) {
      Array.from(verticalOverlay).forEach(function (element) {
        element.addEventListener("click", function () {
          document.body.classList.remove("vertical-sidebar-enable")
        })
      })
    }
  }, [])

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <Link to={'/'} className="logo logo-dark">
          <span className="logo-sm">
            <img src={shortDarkLogo} alt="" height="25" className="tw-h-6" />
          </span>
          <span className="logo-lg">
            <img src={darkLogo} alt="" height="25" className="tw-h-6 " />
          </span>
        </Link>
        <Link to={'/'} className="logo logo-light">
          <span className="logo-sm">
            <img src={shortDarkLogo} alt="" height="25" className="tw-h-6" />
          </span>
          <span className="logo-lg">
            <img src={darkLogo} alt="" height="25" className="tw-h-6 tw-my-6" />
          </span>
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <SimpleBar
        id="scrollbar"
        className="h-100"
      >
        <div className="container-fluid">

          <div id="two-column-menu">
          </div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title">
              <span data-key="t-menu">
                {'Menu'}
              </span>
            </li>

            <ActivableWithIcon
              to={'/'}
              id="sidebarHome"
              icon="ri-home-7-fill"
              label={'Home'}
            />

            <ActivableWithIcon
              to={'/users'}
              id="sidebarUsers"
              icon="ri-group-2-fill"
              label={'Users'}
            />

            <ActivableWithIcon
              to={'/clients'}
              id="sidebarClients"
              icon="ri-service-fill"
              label={'Clients'}
            />

            <ActivableWithIcon
              to={'/emission-categories'}
              id="sidebarEmissionCategories"
              icon="ri-foggy-fill"
              label={'Emission Categories'}
            />

            <ActivableWithIcon
              to={'/emission-factors'}
              id="sidebarEmissionFactors"
              icon="ri-cloud-windy-fill"
              label={'Emission Factors'}
            />

            <ActivableWithIcon
              to={'/eligibility-checks'}
              id="sidebarEligibilityChecks"
              icon="ri-survey-fill"
              label={'Eligibility Checks'}
            />

            {/* <ExpandableWithIcon
              childToToggle="sidebarMeasureChildren"
              id="sidebarMeasure"
              icon="ri-calculator-fill"
              label={'Measure'}
            >
              <Showable
                parentId="#navbar-nav"
                id="sidebarMeasureChildren"
              >
                <Expandable
                  childToToggle="sidebarMeasureEmissionChildren"
                  parentId="#sidebarMeasure"
                  id="sidebarMeasureEmission"
                  label={'Emission'}
                >
                  <Showable
                    parentId="#sidebarMeasure"
                    id="sidebarMeasureEmissionChildren"
                  >
                    <Activable
                      to={'/emission'}
                      id="sidebarMeasureEmissionScopeOneTwo"
                      label={'Scope 1 & 2'}
                    />
                  </Showable>
                </Expandable>
                <Activable
                  to={'/revenue'}
                  id="sidebarMeasureRevenue"
                  label={'Revenue & Production'}
                />
              </Showable>
            </ExpandableWithIcon> */}
          </ul>
        </div>
      </SimpleBar>

      <div className="sidebar-background"></div>
    </div>
  )
}

const FooterSection = () => {
  return (
    <div className="tw-flex tw-justify-start tw-items-center tw-p-5 tw-bg-white">
      <span className="tw-text-[var(--vz-footer-color)]">
        &copy;&nbsp;{(new Date().getFullYear())} TruClimate
      </span>
    </div>
  )
}

export function Root() {
  const location = useLocation()
  const title = location.pathname === '/'
    ? 'home'
    : location.pathname
      .split('-').join(' ')
      .split('/').join(' ')

  return <div id="layout-wrapper">
    <HeaderSection />
    <MenuSection />
    <div className="vertical-overlay"></div>
    <div className={[
      'main-content',
      'tw-flex',
      'tw-flex-col',
      'tw-min-h-screen',
    ].join(' ')}>
      <div className={[
        'tw-flex-1',
        'tw-flex',
        'tw-flex-col',
        'tw-pt-[4.45rem]',
      ].join(' ')}>
        <div className={[
          'tw-bg-white',
          'tw-font-bold',
          'tw-uppercase',
          'tw-text-base',
          'tw-py-2',
          'tw-px-7',
        ].join(' ')}>
          <h4 className="tw-py-3">
            {title}
          </h4>
        </div>
        <Outlet />
      </div>
      <FooterSection />
    </div>
  </div>
}