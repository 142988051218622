import { MaterialReactTable } from 'material-react-table'
import { useModifiedMaterialReactTable } from '../misc/modified_react_table'
import { useAllEmissionCategorySummariesLookup } from '../processes/all_emission_category_summaries_lookup'

export default function EmissionCategories() {
  const {
    data: allEmissionCategorySummaries,
    isLoading: isLoadingAllEmissionCategorySummaries,
    isFetching: isFetchingAllEmissionCategorySummaries,
  } = useAllEmissionCategorySummariesLookup()
  const allEmissionCategorySummariesTable = useModifiedMaterialReactTable({
    data: allEmissionCategorySummaries,
    columns: [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'categoryId',
        Cell: ({ row, cell }) => {
          return <span
            style={{
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'scope',
        header: 'Scope',
        accessorKey: 'categoryScope',
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => {
          return row.categoryName
        },
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
    ],
    getRowId: (row) => {
      return row.categoryId
    },
    enablePagination: true,
    state: {
      isLoading: isLoadingAllEmissionCategorySummaries,
      showProgressBars: isFetchingAllEmissionCategorySummaries,
    },
    // enableRowSelection: false,
    enableRowActions: true,
    // renderRowActions: ({ table, row }) => {
    //   return [
    //     <button
    //       key={row?.original?.id}
    //       title="Confirm changes"
    //       className="btn"
    //     // onClick={
    //     // isRefetchingEmissionCorrections ||
    //     // isMutatingEmissionCorrections
    //     // ? () => { }
    //     // : () => {
    //     // Swal.fire({
    //     //   title: 'Are you sure?',
    //     //   text: "You won't be able to revert this!",
    //     //   icon: 'warning',
    //     //   showCancelButton: true,
    //     //   confirmButtonColor: '#3085d6',
    //     //   cancelButtonColor: '#d33',
    //     // }).then(async (result) => {
    //     //   if (result.isConfirmed) {
    //     //     requestClientsEmissionCorrectionsConfirmation({
    //     //       recordIds: [row?.original?.id]
    //     //     }, {
    //     //       onSettled: () => {
    //     //         table.resetRowSelection()
    //     //       },
    //     //     })
    //     //   }
    //     // })
    //     // }
    //     // }
    //     >
    //       <FaRegCheckCircle fill="green" fontSize={'1.5rem'} />
    //     </button>
    //   ]
    // },
  })

  return <div
    className={[
      'tw-flex-1',
      'tw-flex',
      'tw-flex-col',
      'tw-items-stretch',
      'tw-p-7',
    ].join(' ')}
  >
    <MaterialReactTable
      table={allEmissionCategorySummariesTable}
    />
  </div>
}

