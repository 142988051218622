import { RiInformationLine } from "react-icons/ri"
import { useTotalCarbonProjectsLookup } from "../processes/total_carbon_projects_lookup"
import { useTotalClientsLookup } from "../processes/total_clients_lookup"
import { useTotalExpiringClientsLookup } from "../processes/total_expiring_clients_lookup"
import { useTotalPayingClientsLookup } from "../processes/total_paying_clients_lookup"

function Stat({
  className,
  title,
  info,
  value,
  suffix,
}) {
  return <div
    className={[
      'tw-bg-white',
      'tw-rounded-xl',
      'tw-shadow-[0_1px_2px_rgba(56,_65,_74,_.15)]',
      'tw-transition-all',
      'tw-duration-[.4s]',
      'tw-ease-[ease]',
      'hover:tw-translate-y-[-0.3rem]',
      'hover:tw-shadow-[0_5px_10px_rgba(30,_32,_37,_0.12)]',
      'tw-grid',
      'tw-grid-cols-1',
      'tw-grid-rows-subgrid',
      'tw-gap-6',
      'tw-px-5',
      'tw-pt-5',
      'tw-pb-9',
      ...(className ?? '').split(' '),
    ].join(' ')}
  >
    <p
      className={[
        'tw-flex',
        'tw-items-center',
        'tw-gap-1',
        'fw-medium',
        'text-muted',
      ].join(' ')}
    >
      <span className="">{title}</span>
      {info && <span className="tw-tooltip" data-tip={info}>
        <RiInformationLine className="tw-text-xl" />
      </span>}
    </p>
    <span
      className={[
        'tw-flex',
        'tw-gap-2',
        'tw-items-end',
      ].join(' ')}
    >
      <h3
        className={[
          'ff-secondary',
          'fw-semibold',
          'tw-font-semibold',
          'tw-text-2xl',
        ].join(' ')}
      >
        {value}
      </h3>
      {suffix && <span
        className={[
          'ff-secondary',
          'tw-font-semibold',
          'text-muted',
        ].join(' ')}
      >
        {suffix}
      </span>}
    </span>
  </div>
}

function Stats() {
  const {
    data: totalClients,
  } = useTotalClientsLookup(0)
  const {
    data: totalPayingClients,
  } = useTotalPayingClientsLookup(0)
  const {
    data: totalExpiringClients,
  } = useTotalExpiringClientsLookup(0)
  const {
    data: totalCarbonProjects,
  } = useTotalCarbonProjectsLookup(0)

  return <div
    className={[
      'tw-grid',
      'tw-grid-cols-2',
      'lg:tw-grid-cols-4',
      '[&>*]:tw-row-span-2',
      'tw-gap-4',
    ].join(' ')}
  >
    <Stat
      title={'Total Clients'}
      value={totalClients}
    />
    <Stat
      className={[
        // 'tw-col-span-2',
      ].join(' ')}
      title={'Total Paying Clients'}
      value={totalPayingClients}
      suffix={`/ ${totalClients}`}
    />
    <Stat
      title={'Total Expiring Clients'}
      info={'Within 90 Days'}
      value={totalExpiringClients}
      suffix={`/ ${totalPayingClients}`}
    />
    <Stat
      className={[
        // 'tw-col-span-2',
      ].join(' ')}
      title={'Total Carbon Projects'}
      value={totalCarbonProjects}
    />
    {/* <Stat
      title={'Other Stat '.repeat(13)}
      value={'No Data'}
    />
    <Stat
      title={'Another Stat '.repeat(7)}
      value={'No Data'}
    /> */}
  </div>
}

export default function Home() {
  return <div className={[
    'tw-flex-1',
    'tw-p-6',
    'tw-flex',
    'tw-flex-col',
  ].join(' ')}>
    <Stats />
  </div>
}

