import { useMutation, useMutationState, useQueryClient } from "@tanstack/react-query"
import axios from "axios"

export function useClientRegistration() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['client-registration'],
    mutationFn: async (data) => axios({
      url: [
        process.env.REACT_APP_API_URL,
        '/client-registration',
      ].join(''),
      method: 'POST',
      withCredentials: true,
      data,
    }).then(response => {
      return response?.data ?? {}
    }).catch(error => {
      if (error?.response?.status === 401) {
        queryClient.invalidateQueries({
          queryKey: ['session-lookup'],
        })
      }

      throw error?.response?.data ?? error
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'all-client-summaries-lookup',
        ],
      })
    },
  })
}

export function useClientRegistrationState() {
  const states = useMutationState({
    filters: {
      mutationKey: ['client-registration']
    },
    select: (mutation) => Object.assign(mutation.state, {
      isIdle: mutation.state.status === 'idle',
      isPending: mutation.state.status === 'pending',
      isSuccess: mutation.state.status === 'success',
      isError: mutation.state.status === 'error',
      isDone: mutation.state.status === 'success' ||
        mutation.state.status === 'error'
    }),
  })

  return states[states.length - 1] ?? {}
}