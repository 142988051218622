import { useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "axios"

export function useTotalPayingClientsLookup(initialData) {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['total-paying-clients-lookup'],
    queryFn: async ({ signal }) => {
      return await axios({
        url: [
          process.env.REACT_APP_API_URL,
          '/total-paying-clients-lookup',
        ].join(''),
        method: 'GET',
        withCredentials: true,
        signal,
      }).then(response => {
        return response?.data?.data ?? []
      }).catch(error => {
        if (error?.response?.status === 401) {
          queryClient.invalidateQueries({
            queryKey: ['session-lookup'],
          })
        }

        throw error?.response?.data ?? error
      })
    },
    initialData,
    retry: false,
  })
}