import { Outlet } from "react-router-dom";
import { useSessionLookup } from "../processes/session_lookup";
import { useProfileLookup } from "../processes/profile_lookup";
import { useSessionTermination } from "../processes/session_termination";

export function Private({ accountUrl }) {
  const {
    mutate: sessionTermination,
  } = useSessionTermination();
  const {
    isLoading: isLoadingSession,
    data: session,
  } = useSessionLookup()
  const {
    isLoading: isLoadingProfile,
    data: profile,
  } = useProfileLookup()

  if (isLoadingSession) {
    return <></>;
  } else if (!session) {
    window.location.assign([
      accountUrl,
      '/login?',
      `redirect_=${window.location.href}`,
    ].join(''));

    return <></>;
  } else if (isLoadingProfile) {
    return <></>;
  } else if (!profile || profile.role !== -1) {
    return <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.75rem',
        fontSize: '1.875rem',
        lineHeight: '2.25rem',
        textAlign: 'center',
        fontWeight: 500
      }}
    >
      sorry, you don't have access to the Panel.
      <button
        className='inline'
        onClick={sessionTermination}>
        🙅🏽‍♀️
      </button>
    </div>;
  } else {
    return <Outlet />;
  }
}
