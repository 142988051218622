import { useMutation, useMutationState, useQueryClient } from "@tanstack/react-query"
import axios from "axios"

export function useClientAvatarUpload() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['client-avatar-upload'],
    mutationFn: async (data) => {
      const body = new FormData()
      data.clientId && body.append(
        'clientId',
        data.clientId,
      )
      data.avatar && body.append(
        'avatar',
        data.avatar,
      )

      return axios({
        url: [
          process.env.REACT_APP_API_URL,
          '/client-avatar-upload',
        ].join(''),
        method: 'POST',
        withCredentials: true,
        data: body,
      }).then(response => {
        return response?.data ?? {}
      }).catch(error => {
        if (error?.response?.status === 401) {
          queryClient.invalidateQueries({
            queryKey: ['session-lookup'],
          })
        }

        throw error?.response?.data ?? error
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'all-client-summaries-lookup',
        ],
      })
    },
  })
}

export function useClientAvatarUploadState() {
  const states = useMutationState({
    filters: {
      mutationKey: ['client-avatar-upload']
    },
    select: (mutation) => Object.assign(mutation.state, {
      isIdle: mutation.state.status === 'idle',
      isPending: mutation.state.status === 'pending',
      isSuccess: mutation.state.status === 'success',
      isError: mutation.state.status === 'error',
      isDone: mutation.state.status === 'success' ||
        mutation.state.status === 'error'
    }),
  })

  return states[states.length - 1] ?? {}
}